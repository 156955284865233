import moment from 'moment'

export default {
  randomColor() {
    let randomColor = Math.floor(Math.random()*16777215).toString(16)
    return `#${randomColor}`
  },
  dateToDayString(date) {
    return moment(date).format('YYYY-MM-DD')
  },
  prevDateString(dateStr) {
    return moment(dateStr).subtract(1, 'days').format('YYYY-MM-DD')
  },
  nextDateString(dateStr) {
    return moment(dateStr).add(1, 'days').format('YYYY-MM-DD')
  },

  /**
   * 
   * @param {*} lat1 
   * @param {*} lon1 
   * @param {*} lat2 
   * @param {*} lon2 
   * @param {*} unit   N: 英里, K: 公里, N: 海里
   */
  distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit=="K") { dist = dist * 1.609344 }
        if (unit=="N") { dist = dist * 0.8684 }
        return dist;
    }
}
}
