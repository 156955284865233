<template>
  <div class="base-section">
    <section class="hero is-small top-section">
      <div class="hero-body">
        <div class="container">
          <a href="#" @click="onClickMain"><h1 class="is-1 main-title is-spaced">鹿小編的每日精選</h1></a>
        </div>
      </div>
    </section>
    <div class="container main-section">
      <div class="columns">
        <div class="column">
          <div class="section">
            <b-carousel :autoplay="false" :indicator-inside="false" class="display">
              <b-carousel-item v-for="(photo, i) in photos" :key="i">
                <b-image class="image" :src="photo"></b-image>
              </b-carousel-item>
              <template #indicators="props">
                <b-image class="al image indicator-img" :src="photos[props.i]" :title="props.i"></b-image>
              </template>
            </b-carousel>
          </div>
        </div>
        <div class="column">
          <div class="section main-info">
            <div class="title block">{{productName}}</div>
            <div class="price block">NT$ {{variation[selectedIndex].price}}</div>
            <div class="block">
              <b-field>
                <b-select
                  placeholder="選擇樣式.."
                  v-model="selectedIndex">
                  <option
                    v-for="(item, i) in variation"
                    :value="i"
                    :key="i">
                    {{ item.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="block">
              <b-button type="is-danger"
                icon-pack="fas"
                icon-left="money-check-alt"
                expanded
                @click="onClickPayment"
                >
                立即購買
              </b-button>
            </div>
            <div class="description block">
              <div class="box">
                <!-- <div>商品詳情</div> -->
                <label class="label">商品詳情</label>
                <div v-html="description"></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Footer from './Footer'

export default {
  name: 'Product',
  components: {
    Footer,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      variation: [{
        name: '小甜豆 - 照相機',
        price: 630,
      },{
        name: '小甜豆 - 甜甜圈',
        price: 540,
      },{
        name: '小甜豆 - 草莓牛奶',
        price: 630,
      },{
        name: '客訂',
        price: 1888,
      }
      ],
      photos: [
        '/static/case-photo-1.jpg',
        '/static/case-photo-2.jpg',
        '/static/case-photo-3.jpg',
        '/static/case-photo-4.jpg',
      ],
      productName: '小甜豆系列',
      selectedIndex: 0,
      description:
      '在台現貨 手工製作，小甜豆正版盲盒，發貨會附送原盒卡<br>'+
      '手機殼為硬底軟邊，防摔<br>'+
      '使用iPhone 12/12pro/13/13pro/14/14pro<br>'+
      '下單後7日內發出<br>'+
      '也支持訂製<br>'+
      '訂製產品14-21日發出<br>'+
      '手工訂製產品下單後非質量問題不支持退還<br>'+
      '下單前請三思<br>'+
      '手工作品並非十全十美，完美主義請繞道，感謝！<br>'
    }
  },
  mounted(){

  },
  methods: {
    onClickPayment() {
      this.$buefy.dialog.confirm({
        message: '請聯絡粉絲團客服下單購買',
        onConfirm: () => {
          window.open('https://www.facebook.com/Ann-%E3%81%AE%E6%89%8B%E4%BD%9C-110513934640354')
        }
      })
      // this.$buefy.dialog.alert('請聯絡粉絲團客服付款')
      // window.open('https://www.facebook.com/Ann-%E3%81%AE%E6%89%8B%E4%BD%9C-110513934640354')
    },
    onClickMain() {
      if (this.$route.name !== 'feed') {
        this.$router.push({ name: 'feed' })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
.display {
  .carousel-items {
    text-align: center;
  }
  max-width: 600px;
  
  .indicator-img {
    max-height: 100px;
  }
}
.main-info {
  text-align: left;
  .title {
    font-size: xx-large;
    font-weight: 800;
  }
  .price {
    font-size: xx-large;
  }
}
.top-section {
  .title {
    font-size: 2.5rem;
  }
  // background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url("../assets/top-section-bg.jpg");
  background-image: url("../assets/top-section-bg.jpg");
}
.base-section {
  background: #f4f5f4;
}

.main-title {
  text-align: left;
  cursor: pointer;
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);
}
</style>