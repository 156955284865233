<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <a href="#" @click="onClickMain"><strong>鹿小編的每日精選</strong></a>
      </p>
      <p>
      客服信箱: service@deardeeronline.com
      </p>
    </div>
  </footer>
</template>
<script>

export default {
  name: 'Footer',
  methods: {
    onClickMain() {
      if (this.$route.name !== 'feed') {
        this.$router.push({ name: 'feed' })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
// footer {
//   color: white;
//   background: rgb(14, 14, 14) !important;
// }

// footer strong {
//   color: turquoise;
// }

// footer a {
//   color: turquoise;
// }
</style>