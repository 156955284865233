import axios from 'axios'
import Config from '../config'

axios.defaults.baseURL = Config.apiBase;

export default {
  async getPagesFeed(params) {
    let res = await axios.get('/pages/feed', {params})
    return res.data
  },
  async getDailyFeed(dateStr) {
    const jsonUrl = `${Config.storageBase}/feeds/${dateStr}.json`
    const res = await axios.get(jsonUrl)
    return res.data
  }
}
