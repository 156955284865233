<template>
  <div>
  <section class="hero is-medium top-section">
    <div class="hero-body">
      <div class="container">
        <template v-if="feedType === 'DAILY' || feedType === 'CATEGORY'">
        <h1 class="title is-1 main-title is-spaced" @click.stop.prevent="onClickMain()">鹿小編的每日精選</h1>
        <h2 v-if="feedType === 'DAILY'" class="subtitle"><router-link v-if="nextDateStr" :to="{name: 'feed.date', params: { date: nextDateStr}}">《 </router-link>{{dateStr}} <router-link v-if="prevDateStr" :to="{name: 'feed.date', params: { date: prevDateStr}}">》</router-link></h2>
        <h2 class="subtitle category-list">
          <span :class="($route.params.type==='FOOD')? 'strong' : ''" @click.prevent="onClickCategory('FOOD')"> 美食 </span>/
          <span :class="($route.params.type==='TRAVEL')? 'strong' : ''" @click.prevent="onClickCategory('TRAVEL')"> 旅行 </span>/
          <span :class="($route.params.type==='EVENT')? 'strong' : ''" @click.prevent="onClickCategory('EVENT')"> 活動 </span>/
          <span :class="($route.params.type==='LIFE')? 'strong' : ''" @click.prevent="onClickCategory('LIFE')"> 生活 </span>/
          <span :class="($route.params.type==='STORY')? 'strong' : ''" @click.prevent="onClickCategory('STORY')"> 故事 </span>
        </h2>
        </template>
        <template v-if="feedType === 'SITE'">
        <h2 class="subtitle"><i class="fas fa-user-tag"></i>&nbsp;{{site.name}} ({{total}}篇)</h2>
        <a :href="site.website" target="_blank"><h2 class="subtitle">{{showUrl(site.website)}}</h2></a>
        </template>
      </div>
    </div>
  </section>
  <section class="hero is-large page" v-for="page in pages" v-bind:style="page.style" :key="page.id">
    <div class="hero-body larger">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <!-- <h2 class="subtitle">{{page.subTitle}}</h2> -->
            <!-- <router-link :to="{name:'feed.site', params: {name: page.site.name}}" class="button is-white is-inverted more-btn" @click.stop>
              <i class="fas fa-hashtag"></i>&ensp;{{page.site.name}}
            </router-link> -->
          <b-taglist class="taglist">
            <a href="#" @click.stop.prevent="onClickSite(page.site.name)">
              <b-tag v-if="page.site" type="is-dark"><i class="fas fa-user-tag"></i>&nbsp;{{page.site.name}}</b-tag>
            </a>
            <a href="#" v-for="place in page.placesDetails" :key="place._id" @click.stop.prevent="onPageClick(place.url)">
              <b-tag type="is-dark"><i class="fas fa-map-marker-alt"></i>&nbsp;{{getPlaceLabel(place)}}</b-tag>
            </a>
            <a v-if="page.type === 'SHOPPING'" href="#" @click.stop.prevent="onProductClick(page)">
              <b-tag type="is-danger"><i class="fas fa-shopping-cart"></i>&nbsp;去逛逛</b-tag>
            </a>
          </b-taglist>
          <div class="pc-titles">
            <hr class="content-divider" v-bind:style="page.dividerStyle">
            <h1 class="title is-1 " @click.prevent="onPageClick(page.link)">{{page.title}}</h1>
            <h2 class="subtitle" @click.prevent="onPageClick(page.link)">{{page.subTitle}}</h2>
          </div>
          <b-carousel class="carousel-titles"
            :autoplay="true"
            :interval="5000"
            :pause-hover="false"
            :arrow="false"
            :indicator="false"
            :has-drag="false"
            icon-pack="fas"
            :repeat="true">
              <b-carousel-item v-if="page.title">
                <h1 class="title is-1 " @click="onPageClick(page.link)">{{page.title}}</h1>
              </b-carousel-item>
              <b-carousel-item v-if="page.subTitle">
                <h2 class="subtitle" @click="onPageClick(page.link)">{{page.subTitle}}</h2>
              </b-carousel-item>
          </b-carousel>
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h2 v-if="showLoadNext" class="subtitle loadmore" @click.stop.prevent="onClickLoadMore()">下一頁</h2>
        <h2 v-if="isMainPage" class="subtitle"><router-link v-if="nextDateStr" :to="{name: 'feed.date', params: { date: nextDateStr}}">《 </router-link>{{dateStr}} <router-link v-if="prevDateStr" :to="{name: 'feed.date', params: { date: prevDateStr}}">》</router-link></h2>
      </div>
    </div>
  </section>
  <Footer></Footer>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import Utils from '../common/utils'
import API from '../common/api'
import Footer from './Footer'

const FeedTypeEnum = {
  DAILY: 'DAILY',
  SITE: 'SITE',
  TAG: 'TAG',
  CATEGORY: 'CATEGORY',
}

export default {
  name: 'Feed',
  props: {
    msg: String
  },
  components: {
    Footer,
  },
  data() {
    return {
      pages: null,
      isLoading: false,
      isMainPage: true,
      page: 1,
      limit: 20,
      total: 0,
      showLoadNext: false,
      dateStr: null,
      prevDateStr: null,
      nextDateStr: null,
      coords: null,
      screenWidth: document.documentElement.clientWidth,//螢幕寬度
      feedType: FeedTypeEnum.DAILY,
      site: null,

    }
  },
  mounted(){
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.coords = position.coords
      });
    } else {
      /* geolocation IS NOT available */
    }
    // window.onresize = () => { // 定義視窗大小變更通知事件
    //     this.screenWidth = document.documentElement.clientWidth;
    // };
    // this.loadData()
  },
  beforeRouteEnter (to, from, next) {
    console.log('on beforeRouteEnter')
    next(vm => {
      vm.loadData()
    })
  },
  beforeRouteUpdate(to, from , next) {
    console.log('on beforeRouteUpdate')
    console.log(to)
    console.log(from)
    if (to.name === 'feed.date') {
      this.loadData(to.params)
    }
    next()
  },
  methods: {
    async loadData(params) {
      window.scrollTo(0,0)
      let todayStr = Utils.dateToDayString()
      switch (this.$route.name) {
        case 'feed':
          this.feedType = FeedTypeEnum.DAILY
          this.nextDateStr = null
          this.dateStr = Utils.dateToDayString()
          this.prevDateStr = Utils.prevDateString(this.dateStr)
          break;
        case 'feed.date':
          this.feedType = FeedTypeEnum.DAILY
          this.dateStr = (params)? params.date : this.$route.params.date
          if (this.dateStr === todayStr) {
            this.nextDateStr = null
          } else {
            this.nextDateStr = Utils.nextDateString(this.dateStr)
          }
          this.prevDateStr = Utils.prevDateString(this.dateStr)
          break;
        case 'feed.site':
          this.feedType = FeedTypeEnum.SITE
          break;
        case 'feed.category':
          this.feedType = FeedTypeEnum.CATEGORY
          break;
        default:
          this.nextDateStr = null
          this.dateStr = null
          this.prevDateStr = null
      }

      this.isLoading = true
      let data = null
      if (this.$route.query.mode) {
        data = await API.getPagesFeed({
          status: 'CREATED',
          page: this.page,
          limit: this.limit,
          mode: this.$route.query.mode,
          siteId: this.$route.query.siteId,
        })
        this.isMainPage = false
      } else if (this.$route.params.name) {
        data = await API.getPagesFeed({
          page: this.page,
          limit: this.limit,
          siteName: this.$route.params.name,
        })
        this.site = data.site
        this.total = data.total
        this.isMainPage = false
        if (this.page * this.limit < data.total) {
          this.showLoadNext = true
        } else {
          this.showLoadNext = false
        }
      } else if (this.$route.params.type) {
        data = await API.getPagesFeed({
          page: this.page,
          limit: this.limit,
          type: this.$route.params.type,
        })
        this.total = data.total
        this.isMainPage = false
      } else {
        // data = await API.getPagesFeed({
        //   page: this.page,
        //   limit: this.limit,
        // })
        try {
          data = await API.getDailyFeed(this.dateStr)
          data.pages.splice(1, 0, {
            bannerUrl: '',
            cover: '/static/banner-phone-case1.jpg',
            coverUrl: 'static/banner-phone-case1.jpg',
            createDate: '2020-09-05T01:00:02.088Z',
            description: '',
            id: '5f52e31255446fcfb7ea2301',
            modifyDate: '2020-09-14T10:04:03.539Z',
            places: [],
            placesDetails: [],
            site: {
              name: 'Ann の手作',
              website: 'https://www.facebook.com/Ann-%E3%81%AE%E6%89%8B%E4%BD%9C-110513934640354',
              siteId: '5f3f37ab5d41ae2e313e7c87',
              status: 'ONLINE',
            },
            tags: [],
            title: '奶油膠手機殼 - 小甜豆系列',
            type: 'SHOPPING',
            url: '/#/p/5b1a5576df912150f5aab1e2',
            productId: '5b1a5576df912150f5aab1e2',
          })
          data.total += 1

        } catch (e) {
          if (this.$route.name === 'feed.date') {
            this.$router.push({ name: 'feed' })
            return;
          }
        }
        this.isMainPage = true
      }
      if (!data) return;

      console.log(data)
      
      let pageItems = data.pages.map((item) => {
        let color = Utils.randomColor()
        item.placesDetails = item.placesDetails.filter(place => place !== null)
        let coverUrl = item.coverUrl
        let isBanner = false
        if (this.screenWidth > 768 && item.bannerUrl && 
          item.bannerUrl !== 'https://storage.deerlongstay.com/siteimages' &&
          item.bannerUrl !== 'https://storage.deerlongstay.com/siteimagesundefined') {
          coverUrl = item.bannerUrl
          isBanner = true
        }
        const pageItem = {
          title: item.title,
          subTitle: item.description,
          link: item.url,
          style: {
            backgroundImage: `linear-gradient(rgba(0,0,0,0) 50%,rgba(0,0,0,0.8)),url(${coverUrl})`,
            borderTop: `10px solid ${color}`,
          },
          dividerStyle: {
            background: color,
          },
          site: item.site,
          placesDetails: item.placesDetails,
          type: item.type,
          productId: item.productId,
        }
        if (isBanner) {
          pageItem.style.backgroundSize = 'contain'
          pageItem.style.backgroundPosition = 'top'
        }

        return pageItem
      })

      this.pages = pageItems
      this.isLoading = false
    },
    onClickMain() {
      if (this.$route.name !== 'feed') {
        this.$router.push({ name: 'feed' })
      }
      this.page = 1
      this.showLoadNext = false
      this.loadData()
    },
    onClickLoadMore() {
      this.page = this.page + 1
      this.showLoadNext = false
      this.loadData()
      window.scrollTo(0,0)
    },
    onClickSite(name) {
      console.log(`onClickSite ${name}`)
      if (this.$route.name !== 'feed.site') {
        this.$router.push({ name: 'feed.site', params: { name } })
      }
      
      this.page = 1
      this.showLoadNext = false
      this.loadData()
      window.scrollTo(0,0)
    },
    onClickCategory(type) {
      console.log(`onClickCategory ${type}`)
      this.$router.push({ name: 'feed.category', params: { type } })
      
      this.page = 1
      this.showLoadNext = false
      this.loadData()
      window.scrollTo(0,0)
    },
    onPageClick(url) {
      console.log('onPageClick')
      if (this.isLoading) {
        console.log('do nothing.')
        return;
      }
      // this.isLoading = true
      // setTimeout(() => {
      //   this.isLoading = false
      // }, 1000)
      // window.location.href = url
      window.open(url)
    },
    onProductClick(page) {
      this.$router.push({ name: 'product.id', params: { id: page.productId } })
    },
    getPlaceLabel(place) {
      let dist = this.getDistance(place)
      let label = (place.name.length > 23) ? place.name.substring(0, 20) + '...' : place.name
      if (dist) {
        label += dist
      }
      return label
    },
    getDistance(place) {
      if (place && this.coords) {
        let dist = Utils.distance(this.coords.latitude, this.coords.longitude, place.location.lat, place.location.lng, 'K')
        return ` - ${dist.toFixed(1)}km`
      }
      return null
    },
    showUrl(url) {
      return url.replace('https://', '').replace('http://', '')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.va {
  display: flex;
  align-items: center;
}

.hero,
.cta {
  background: url(data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.4296875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E), linear-gradient(52deg, #42475C 0%, #20222E 71%);
}

.cta {
  height: 500px;
  border-top: 10px solid turquoise;
}


.title {
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  font-family: 'Nunito Sans', sans-serif;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);
}

.subtitle {
  color: white;
  font-size: 1rem;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);
}

hr.content-divider {
  max-width: 75px;
  height: 5px;
}

#parallax-1 {
  background: url(https://source.unsplash.com/featured/?nature);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid coral;
}

#parallax-1 hr {
  background: coral;
}

#parallax-2 {
  background: url(https://source.unsplash.com/featured/?computers);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid yellow;
}

#parallax-2 hr {
  background: yellow;
}

#parallax-3 {
  background: url(https://source.unsplash.com/featured/?city);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid hotpink;
}

#parallax-3 hr {
  background: hotpink;
}

.label {
  color: white;
}

input {
  background: transparent !important;
  border: 1px solid turquoise !important;
  color: white !important;
}

input::placeholder {
  color: white !important;
}

.page {
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid yellow;
}
.page .larger {
  padding: 12rem 1rem 1rem 1rem;
}
.more-btn {
  opacity: 0.5;
  margin: 2px;
  max-width: 300px;
  overflow: hidden;
  justify-content: normal;
}
.top-section {
  .title {
    font-size: 2.5rem;
  }
  // background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url("../assets/top-section-bg.jpg");
  background-image: url("../assets/top-section-bg.jpg");
}
.main-title {
  cursor: pointer;
}
.loadmore {
  cursor: pointer;
}
.taglist {
  margin-bottom: 0.5rem;
}
.tag {
  margin: 1px;
}
.carousel-titles {
  min-height: 80px;
  height: 80px;
  overflow: hidden;
  text-align: left;
  display: block;
  cursor: pointer;
}
.pc-titles {
  display: none;
  cursor: pointer;
}
.strong {
  color: turquoise;
  font-weight: 700;
}
.category-list {
  span {
    cursor: pointer;
  }
}
@media screen and (min-width: 769px) {
  .page .larger {
    padding: 18rem 1.5rem 2rem 1.5rem;
  }
  .carousel-titles {
    display: none;
  }
  .pc-titles {
    display: block;
    text-align: left;
  }
  .hr.content-divider {
    display: block;
  }
}
</style>
