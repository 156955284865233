import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import NotFound from './components/404.vue'
import Feed from './components/Feed.vue'
import Product from './components/Product.vue'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Buefy)

let routes = [
  {
    path: '/404',
    component: NotFound,
    name: '404',
  },
  {
    path: '/',
    redirect: '/daily',
    // component: Feed,
    // name: 'feed',
  },
  {
    path: '/s/:name',
    component: Feed,
    name: 'feed.site',
  },
  {
    path: '/category/:type',
    component: Feed,
    name: 'feed.category',
  },
  {
    path: '/daily',
    component: Feed,
    name: 'feed',
  },
  {
    path: '/daily/:date',
    component: Feed,
    name: 'feed.date',
  },
  {
    path: '/daily/:date',
    component: Feed,
    name: 'feed.date',
  },
  {
    path: '/product',
    component: Product,
    name: 'product',
  },
  {
    path: '/p/:id',
    component: Product,
    name: 'product.id',
  },
  {
    path: '*',
    redirect: { path: '/404' }
  }
];
const router  = new VueRouter({ routes })
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
